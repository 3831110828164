@font-face {
  font-family: 'Microsoft Tai Le';
  src: url('./fonts/taileb.ttf') format('woff2'),
    url('./fonts/taileb.ttf') format('woff');
  /* Add additional formats if needed */
}

.form-control::placeholder,
p,
.form-control {
  color: #134383;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.form-control::placeholder {
  font-size: 14px;
}

body {
  background-color: #f0f0f0;
  height: 100vh;
}
.centeralize {
  margin-top: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 375px; */
}

#root {
  position: relative;
  height: 100vh;
  margin-bottom: 50px;
}

/* header & footer */
.arved-navbar,
.arved-footer {
  background-color: #134383;
  position: fixed;
  left: 0px;
  width: 100%;
  display: flex;
}

.arved-footer {
  justify-content: center;
  align-items: center;
  bottom: 0px;
  height: fit-content;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: white;
}

.arved-footer > p {
  /* justify-content: center; */
  /* align-items: center; */
  /* bottom: 0px; */
  /* height: fit-content; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: white;
}

.arved-navbar {
  background-color: #134383;
  top: 0px;
  left: 0;
  height: 60px;
  z-index: 1000;
}

.arved-text {
  font-family: 'Cinzel', sans-serif;
  font-size: 104px;
  /* width: fit-content; */
}

/* labels and inputs*/
.arved-input-label,
.arved-input-label2 {
  background-color: #f0f0f0;
  border: none;
  border-radius: 0%;
  height: 30px;
}
.arved-input-label2.label-with-2-icons {
  width: calc(100% - 48px) !important;
}

.arved-input-label2 {
  width: calc(100% - 24px) !important;
  padding-left: 20px;
  padding-bottom: 6px;
  background-color: #ffffff;
  margin-left: 0px !important;
}

.arved-input-label2:focus {
  box-shadow: none;
  outline: 0;
  color: #28a745;
  background-color: transparent;
}

.arved-input-label2:disabled {
  background-color: #ffffff;
  opacity: 50%;
}

.arved-input-label:focus {
  box-shadow: none;
  outline: 0;
  background-color: transparent;
}
.arved-input-group {
  border-bottom: 2px solid #134383;
  color: #134383;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 30px;
}

.button-group {
  width: 30%;
}
.arved-button {
  background-color: #ffffff;
  color: #134383;
  border-radius: 5px;
  border: 2px solid #134383;
  font-size: 16px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  width: 50%;
  margin-top: 14px;
}
.arved-button:hover {
  background-color: #134383;
  border-color: #134483b4;
  color: #ffffff;
}

.arved-button1 {
  background-color: #f0f0f0;
  color: #134383;
  border-radius: 4px;
  border: 2px solid #134383;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.arved-button1:hover {
  background-color: #134383;
  border-color: #134483b4;
  color: #f0f0f0;
}
.arved-button2 {
  background-color: #ffffff;
  color: #134383;
  font-size: 16px;
  border: 0px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  outline: none;
}
.arved-button2:hover {
  background-color: #ffffff;
  color: #134383;
  text-decoration: underline;
}
.arved-button2:focus {
  outline: none;
}

.arved-button3,
.arved-button4 {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  border: 0px;
  border-radius: 2px;
  height: 48px;
  width: 100px;
}
.arved-button3 {
  background-color: #134383;
  color: #ffffff;
}
.arved-button4 {
  background-color: #f0f0f0;
  color: #134383;
}
.arved-button3:hover {
  background-color: #28a745;
  color: #ffffff;
}
.arved-button4:hover {
  background-color: #dc3545;
  color: #ffffff;
}
/*texts*/
.new-account-text {
  font-size: 19px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: #495a70;
}
.forgot-password-text {
  font-size: 15px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  color: #949494;
}
.forgot-password-text a {
  color: #949494;
  text-decoration: underline;
}
.forgot-password-text a:hover {
  color: #134383;
  text-decoration: underline;
}

.nav-link {
  fill: #fff;
  margin: 20px;
}

.nav-link-stroke {
  stroke: #fff;
}

.nav-link-text {
  margin-top: 1px;
  margin-left: 1px;
}
.navbar {
  padding: 0;
}

.option {
  margin: 0px 8px;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;

  font-size: 15px;
  display: flex;
  align-items: center;
  /* justify-content: space-; */
  gap: 2px;
}

.navbar-brand {
  font-size: 180%;
}

.profile-photo {
  margin-left: 45px;
}

.userInfo {
  position: relative;
  height: fit-content;
  margin-left: 25px;
  width: 820px;
  top: 25px;
}

.userInfo > button {
  position: absolute;
  top: 0;
  right: 0;
}

.fields {
  width: 500px;
}

@media only screen and (max-width: 1200px) {
  .userInfo {
    width: 640px;
  }
}

@media only screen and (max-width: 992px) {
  .userInfo {
    width: 460px;
  }

  .profile-photo {
    margin-left: 0px;
  }

  .userInfo {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .userInfo {
    width: 460px;
    top: 15px;
  }
}

.title {
  color: #134383;
  font-weight: bold;
  font-family: Microsoft Tai Le;
  font-size: 33px;
  padding: 0;
  margin-bottom: 5px;
}

.content {
  margin: 90px 0px;
}
.product-page {
  width: 70%;
  margin: 90px auto;
}
.form {
  background-color: #ffffff;
  border: 1px solid #707070;
}
.form-text {
  margin-left: 20px;
  margin-bottom: 5px;
  color: #134383;
  font-size: 33px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.page-title-text {
  margin-bottom: 5px;
  color: #134383;
  font-size: 33px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.profile {
  width: 100%;
  /* height: 180px; */
  padding-bottom: 20px;
  margin-bottom: 20px;
  border: 1px solid #707070;
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  box-sizing: content-box;
}

.department,
.field,
.table th,
.table td,
.product-title,
.product-author,
.product-magazine,
.view-product-field,
.view-product-title,
.view-product-field-text,
.field-12-bold-arial,
.field-14-bold-arial,
.field-16-bold-arial,
.field-18-bold-arial,
.field-20-bold-arial {
  color: #134383;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.field-12-arial {
  font-size: 12px;
}

.field-12-bold-arial {
  font-size: 12px;
}
.field-14-bold-arial {
  font-size: 14px;
}
.field-16-bold-arial {
  font-size: 16px;
}
.field-18-bold-arial {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}

.department {
  margin: 0px 0px 15px;
  opacity: 34%;
}
.field {
  /* width: 50%; */
  font-size: 16px;
  padding-left: 10px;
  margin-bottom: 5px;

  position: relative;
}

.field::before {
  content: '';
  position: absolute;
  left: 0px;
  width: 4px;
  height: 4px;
  background-color: #134383;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.product-bar {
  width: 100%;
  height: 53px;
  margin-bottom: 20px;
  border: 1px solid #707070;
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
}
.products {
  width: 100%;
}
.product {
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
  border: 1px solid #707070;
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
}

.product-section {
  width: 70%;
}
.product-type {
  width: 120px;
  text-align: center;
}
.product-year {
  width: fit-content;
  margin-left: 8px;
  text-align: center;
}
.product-type-year {
  width: 200px;
  margin-right: 0px;
  display: flex;
}
.product-title {
  margin: 0px;
  line-height: 1.3em;
}
.product-title:hover {
  cursor: pointer;
  margin: 0px;
  text-decoration: underline;
  color: #134483d3;
}
.view-product-title {
  font-size: 24px;
  line-height: 1.3;
}
.view-product-field {
  font-size: 16px;
  text-align: right;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.view-product-field-text {
  font-size: 16px;
  margin-bottom: 10px;
  color: black;
  display: flex;
  align-items: center;
  gap: 7px;
  overflow: hidden;
  padding: 0;
  text-transform: capitalize;
}
.view-product-field-document {
  color: black;
}
.view-product-field-document:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #134483d3;
}
.product-author {
  margin: 0px;
  font-size: 16px;
  color: #85909e;
}
.product-magazine {
  margin: 0px;
  font-size: 16px;
  color: #85909e;
}
.table th,
.product-title {
  font-size: 20px;
  border-width: 0px;
}
.table td {
  font-size: 20px;
  border: transparent;
}

textarea[name='description'] {
  margin-top: 15px;
  border: 2px solid #134383;
}

input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}
.select-group {
  width: calc(100% - 24px) !important;
  padding-left: 16px;
  border: 0;
  outline: 0;
  color: #134383;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  /* margin-left: 10px; */
  text-transform: capitalize;
  height: 30px;
}
input[type='file']::-webkit-file-upload-button {
  display: none;
}

.no-style {
  padding: 0;
  background-color: transparent;
  border: 0;
  color: transparent;
}

.accept-button {
  color: #0d7a1c;
}
.reject-button {
  color: #820a0a;
}

.reject-button-text,
.accept-button-text {
}

.no-style:focus {
  outline: 0;
  border: 0;
}
.download-result {
  padding: 0;
  background-color: transparent;
  border: 0;
  color: #134383;
  text-decoration: underline;
}
.download-result:hover {
  color: #007bff;
}
.download-result:focus {
  outline: 0;
  border: 0;
}
.hidden {
  visibility: hidden;
}

.modal-content {
  border-radius: 0px;
}

.admin-option {
  text-align: center;
  width: fit-content;
  font-weight: 500;
  border-bottom: 2px solid #134383;
  display: flex;
  align-items: center;
  margin: 0px 15px;
}

.admin-option-text {
  color: #134383;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
.admin-option-icon {
  margin-right: 5px;
}
.admin-option:hover {
  text-decoration: none;
  border-bottom: 2px solid #007bff;
}

li {
  padding-right: 0%;
}
li span {
  position: relative;
  left: -20px;
  padding-right: 0px;
}

@media only screen and (max-width: 992px) {
  .option {
    color: #134383;
    padding: 10px;
  }

  .title {
    font-size: 24px;
  }

  .navbar-links {
    background-color: #fff;
  }

  .nav-link {
    fill: #134383;
  }

  .nav-link-stroke {
    stroke: #134383;
  }

  .product-page {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .profile-photo {
    display: none;
    width: 90%;
    background-color: #007bff;
  }
  .form-text {
    margin-left: 0px;
    font-size: 24px;
  }

  .product-page {
    width: 90%;
  }
}

.reject-button-icon,
.accept-button-icon {
  display: none;
}

@media only screen and (max-width: 576px) {
  .profile {
    height: fit-content !important;
    padding-bottom: 20px;
  }
  .userInfo {
    top: 15px;
    width: 95%;
  }

  .title {
    font-size: 20px;
  }
  .department {
    font-size: 16px;
  }

  .field {
    font-size: 14px;
  }

  .form-text {
    font-size: 24px;
  }

  .product-page {
    width: 90%;
  }
}

.tooltip-text {
  visibility: hidden;
  width: 60px;
  font-size: 10px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%; /* Position the tooltip above the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.reject-button-icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  background-color: #820a0a;
}

.accept-button-icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
  background-color: #0d7a1c;
}

@media only screen and (max-width: 480px) {
  .reject-button-text,
  .accept-button-text {
    display: none;
  }

  .reject-button-icon,
  .accept-button-icon {
    display: block;
  }
}

@media only screen and (max-width: 440px) {
  .navbar-brand {
    font-size: 120%;
  }
  .product-type,
  .product-type-year {
    display: none;
  }

  .form-text {
    font-size: 18px;
  }

  .product-page {
    width: 90%;
  }

  .arved-text {
    font-family: 'Cinzel', sans-serif;
    font-size: 80px;
    /* width: fit-content; */
  }

  .pending_account_profile_photo {
    display: none;
  }

  .userInfo > button {
    display: none !important;
  }
}

@media only screen and (max-width: 360px) {
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top: 5px solid #134383;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
